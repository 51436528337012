<template>
  <div>
    <b-card class="m-2">
      <b-row>
        <b-col md="6">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Sales Statement Report ID :
            </dt>
            <dd class="col-sm-7">
              {{ salesStatementReportId }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Report Period :
            </dt>
            <dd class="col-sm-7">
              {{ startDate }} ~ {{ endDate }}
            </dd>
          </dl>
        </b-col>

        <b-col md="6">
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Merchant :
            </dt>
            <dd class="col-sm-7">
              {{ merchantName }}
            </dd>
          </dl>
          <dl class="row">
            <dt class="col-sm-5 text-right">
              Report Generated Date :
            </dt>
            <dd class="col-sm-7">
              {{ createTime }} By {{ createBy }}
            </dd>
          </dl>
        </b-col>
      </b-row>
    </b-card>

    <b-card class="m-2">
      <b-row class="text-center">
        <b-col class="font-weight-bold h3 bg-light-primary">
          Description
        </b-col>
        <b-col class="font-weight-bold h3 bg-light-primary">
          Success
        </b-col>
        <b-col class="font-weight-bold h3 bg-light-primary">
          Charegeback
        </b-col>
        <b-col class="font-weight-bold h3 bg-light-primary">
          Refund
        </b-col>
        <b-col class="font-weight-bold h3 bg-light-primary">
          Total
        </b-col>
      </b-row>
      <!-- Quantity -->
      <b-row
        align-v="center"
        class="text-center"
      >
        <b-col class="p-1">
          Quantity
        </b-col>
        <b-col class="p-1">
          <b-form-input
            v-model="successQuantity"
            class="text-center"
            type="number"
            step="1"
            trim
            :formatter="numberFormatter"
          />
        </b-col>
        <b-col class="p-1">
          <b-form-input
            v-model="chargebackQuantity"
            class="text-center"
            type="number"
            step="1"
            trim
            :formatter="numberFormatter"
          />
        </b-col>
        <b-col class="p-1">
          <b-form-input
            v-model="refundQuantity"
            class="text-center"
            type="number"
            step="1"
            trim
            :formatter="numberFormatter"
          />
        </b-col>
        <b-col class="p-1">
          {{ totalQuantity }}
        </b-col>
      </b-row>
      <!-- Payable Amount -->
      <b-row
        align-v="center"
        class="text-center"
      >
        <b-col class="p-1">
          Payable Amount
        </b-col>
        <b-col class="p-1">
          <b-input-group
            :prepend="successPayCurrency"
            class="input-group-merge"
          >
            <b-form-input
              v-model="successPayAmount"
              class="text-center"
              type="number"
              step="0.01"
              trim
            />
          </b-input-group>
        </b-col>
        <b-col class="p-1">
          <b-input-group
            :prepend="chargebackPayCurrency"
            class="input-group-merge"
          >
            <b-form-input
              v-model="chargebackPayAmount"
              class="text-center"
              type="number"
              step="0.01"
              trim
            />
          </b-input-group>
        </b-col>
        <b-col class="p-1">
          <b-input-group
            :prepend="refundPayCurrency"
            class="input-group-merge"
          >
            <b-form-input
              v-model="refundPayAmount"
              class="text-center"
              type="number"
              step="0.01"
              trim
            />
          </b-input-group>
        </b-col>
        <b-col class="p-1">
          {{ totalPayCurrency }} {{ totalPayAmount | numberFilter }}
        </b-col>
      </b-row>
      <!-- Processing Fee -->
      <b-row
        align-v="center"
        class="text-center"
      >
        <b-col class="p-1">
          Processing Fee
        </b-col>
        <b-col class="p-1">
          <b-input-group
            :prepend="successProcessingFeeCurrency"
            class="input-group-merge"
          >
            <b-form-input
              v-model="successProcessingFeeAmount"
              class="text-center"
              type="number"
              step="0.01"
              trim
            />
          </b-input-group>
        </b-col>
        <b-col class="p-1">
          <b-input-group
            :prepend="chargebackProcessingFeeCurrency"
            class="input-group-merge"
          >
            <b-form-input
              v-model="chargebackProcessingFeeAmount"
              class="text-center"
              type="number"
              step="0.01"
              trim
            />
          </b-input-group>
        </b-col>
        <b-col class="p-1">
          <b-input-group
            :prepend="refundProcessingFeeCurrency"
            class="input-group-merge"
          >
            <b-form-input
              v-model="refundProcessingFeeAmount"
              class="text-center"
              type="number"
              step="0.01"
              trim
            />
          </b-input-group>
        </b-col>
        <b-col class="p-1">
          {{ totalProcessingFeeCurrency }} {{ totalProcessingFeeAmount | numberFilter }}
        </b-col>
      </b-row>
      <!-- TAX -->
      <b-row
        v-if="taxName != ''"
        align-v="center"
        class="text-center"
      >
        <b-col class="p-1">
          {{ taxName }}
        </b-col>
        <b-col class="p-1" />
        <b-col class="p-1" />
        <b-col class="p-1" />
        <b-col class="p-1">
          <b-input-group
            :prepend="taxCurrency"
            class="input-group-merge"
          >
            <b-form-input
              v-model="taxAmount"
              class="text-center"
              type="number"
              step="0.01"
              trim
            />
          </b-input-group>
        </b-col>
      </b-row>
      <!-- SETTLEABLE AMOUNT -->
      <b-row
        align-v="center"
        class="text-center"
      >
        <b-col class="p-1">
          SETTLEABLE AMOUNT
        </b-col>
        <b-col class="p-1" />
        <b-col class="p-1" />
        <b-col class="p-1" />
        <b-col class="p-1">
          {{ settleableCurrency }} {{ settleableAmount | numberFilter }}
        </b-col>
      </b-row>
    </b-card>
    <!-- Upload Report -->
    <b-card v-if="$can('write', 'Settlement.Report')" class="m-2">
      <b-row>
        <b-col md="4">
          <b-form-file
            v-model="reportFile"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
          />
        </b-col>
        <b-col md="4">
          <b-button
            v-if="reportFile"
            variant="primary"
            @click="uploadReport"
          >
            Upload
          </b-button>
          <b-button
            v-if="reportFile"
            class="ml-1"
            variant="primary"
            @click="reportFile = null"
          >
            Clear
          </b-button>
        </b-col>
      </b-row>
    </b-card>
    <!-- Save Button -->
    <b-card v-if="$can('write', 'Settlement.Report')" class="m-2">
      <b-button
        variant="primary"
        @click="saveConfirm"
      >
        Save
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BInputGroup, BFormFile, BButton,
} from 'bootstrap-vue'
import router from '@/router'
import { showToast } from '@/libs/tool'
import useSettlementJs from './settlement'

const {
  querySalesStatementReportDetail,
  updateSalesStatementDetail,
  uploadReportFile,
} = useSettlementJs()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BInputGroup,
    BFormFile,
    BButton,
  },
  filters: {
    numberFilter(value) {
      let realVal = ''
      if (value || value === 0) {
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = '--'
      }
      return realVal
    },
  },
  data() {
    return {
      settlementId: router.currentRoute.params.id,
      salesStatementReportId: router.currentRoute.query.sales_statement_report_id,
      startDate: router.currentRoute.query.start_date,
      endDate: router.currentRoute.query.end_date,
      merchantName: router.currentRoute.query.merchant_name,
      createTime: router.currentRoute.query.create_time,
      createBy: router.currentRoute.query.create_by,
      successQuantity: 0,
      chargebackQuantity: 0,
      refundQuantity: 0,
      totalQuantity: 0,
      successPayCurrency: 'BRL',
      successPayAmount: 0,
      chargebackPayCurrency: 'BRL',
      chargebackPayAmount: 0,
      refundPayCurrency: 'BRL',
      refundPayAmount: 0,
      totalPayCurrency: 'BRL',
      totalPayAmount: 0,
      successProcessingFeeCurrency: 'BRL',
      successProcessingFeeAmount: 0,
      chargebackProcessingFeeCurrency: 'BRL',
      chargebackProcessingFeeAmount: 0,
      refundProcessingFeeCurrency: 'BRL',
      refundProcessingFeeAmount: 0,
      totalProcessingFeeCurrency: 'BRL',
      totalProcessingFeeAmount: 0,
      taxName: '',
      taxCurrency: 'BRL',
      taxAmount: 0,
      settleableCurrency: 'BRL',
      settleableAmount: 0,
      reportFile: null,
      detail: {},
    }
  },
  watch: {

    successQuantity() {
      this.totalQuantity = parseInt(this.successQuantity, 0) + parseInt(this.chargebackQuantity, 0) + parseInt(this.refundQuantity, 0)
    },
    chargebackQuantity() {
      this.totalQuantity = parseInt(this.successQuantity, 0) + parseInt(this.chargebackQuantity, 0) + parseInt(this.refundQuantity, 0)
    },
    refundQuantity() {
      this.totalQuantity = parseInt(this.successQuantity, 0) + parseInt(this.chargebackQuantity, 0) + parseInt(this.refundQuantity, 0)
    },
    successPayAmount() {
      this.totalPayAmount = parseFloat(this.successPayAmount) + parseFloat(this.chargebackPayAmount) + parseFloat(this.refundPayAmount)
    },
    chargebackPayAmount() {
      this.totalPayAmount = parseFloat(this.successPayAmount) + parseFloat(this.chargebackPayAmount) + parseFloat(this.refundPayAmount)
    },
    refundPayAmount() {
      this.totalPayAmount = parseFloat(this.successPayAmount) + parseFloat(this.chargebackPayAmount) + parseFloat(this.refundPayAmount)
    },
    successProcessingFeeAmount() {
      this.totalProcessingFeeAmount = parseFloat(this.successProcessingFeeAmount) + parseFloat(this.chargebackProcessingFeeAmount) + parseFloat(this.refundProcessingFeeAmount)
    },
    chargebackProcessingFeeAmount() {
      this.totalProcessingFeeAmount = parseFloat(this.successProcessingFeeAmount) + parseFloat(this.chargebackProcessingFeeAmount) + parseFloat(this.refundProcessingFeeAmount)
    },
    refundProcessingFeeAmount() {
      this.totalProcessingFeeAmount = parseFloat(this.successProcessingFeeAmount) + parseFloat(this.chargebackProcessingFeeAmount) + parseFloat(this.refundProcessingFeeAmount)
    },
    totalPayAmount() {
      this.settleableAmount = parseFloat(this.totalPayAmount) + parseFloat(this.totalProcessingFeeAmount) + parseFloat(this.taxAmount)
    },
    totalProcessingFeeAmount() {
      this.settleableAmount = parseFloat(this.totalPayAmount) + parseFloat(this.totalProcessingFeeAmount) + parseFloat(this.taxAmount)
    },
    taxAmount() {
      this.settleableAmount = parseFloat(this.totalPayAmount) + parseFloat(this.totalProcessingFeeAmount) + parseFloat(this.taxAmount)
    },
  },
  created() {
    this.querySalesStatementReportDetail()
  },
  methods: {
    numberFormatter(number) {
      if (number === '') {
        return 0
      }
      return parseInt(number, 0)
    },
    handlePriceInput(value) {
      let realVal = ''
      if (value) {
        realVal = parseFloat(value).toFixed(2)
      } else {
        realVal = parseFloat(0).toFixed(2)
      }
      return realVal
    },
    querySalesStatementReportDetail() {
      querySalesStatementReportDetail(
        this.settlementId,
        detail => {
          this.initDetail(detail)
        },
        fail => {
          showToast(this, 'Warning', `Query SalesStatement Report Detail Failed with ${fail}`)
        },
      )
    },
    initDetail(detail) {
      this.detail = detail
      this.successQuantity = detail.success.quantity
      this.successPayCurrency = detail.success.pay_amount.currency
      this.successPayAmount = this.handlePriceInput(detail.success.pay_amount.amount)
      this.successProcessingFeeCurrency = detail.success.processing_fee.currency
      this.successProcessingFeeAmount = this.handlePriceInput(detail.success.processing_fee.amount)

      this.chargebackQuantity = detail.chargeback.quantity
      this.chargebackPayCurrency = detail.chargeback.pay_amount.currency
      this.chargebackPayAmount = this.handlePriceInput(detail.chargeback.pay_amount.amount)
      this.chargebackProcessingFeeCurrency = detail.chargeback.processing_fee.currency
      this.chargebackProcessingFeeAmount = this.handlePriceInput(detail.chargeback.processing_fee.amount)

      this.refundQuantity = detail.refund.quantity
      this.refundPayCurrency = detail.refund.pay_amount.currency
      this.refundPayAmount = this.handlePriceInput(detail.refund.pay_amount.amount)
      this.refundProcessingFeeCurrency = detail.refund.processing_fee.currency
      this.refundProcessingFeeAmount = this.handlePriceInput(detail.refund.processing_fee.amount)

      this.totalQuantity = detail.total.quantity
      this.totalPayCurrency = detail.total.pay_amount.currency
      this.totalPayAmount = detail.total.pay_amount.amount
      this.totalProcessingFeeCurrency = detail.total.processing_fee.currency
      this.totalProcessingFeeAmount = detail.total.processing_fee.amount

      this.taxName = detail.tax.name
      this.taxCurrency = detail.tax.source_amount.currency
      this.taxAmount = this.handlePriceInput(detail.tax.source_amount.amount)

      this.settleableCurrency = detail.settleable.source_amount.currency
      this.settleableAmount = this.handlePriceInput(detail.settleable.source_amount.amount)
    },
    uploadReport() {
      const params = {
        fileType: 1,
        settlementId: this.settlementId,
        formData: this.reportFile,
      }
      uploadReportFile(
        params,
        () => {
          showToast(this, 'Success', 'Upload Success.')
        },
        fail => {
          showToast(this, 'Warning', `Upload Failed with ${fail}`)
        },
      )
    },
    saveConfirm() {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.saveReportDetail()
        }
      })
    },
    saveReportDetail() {
      this.detail.success.quantity = this.successQuantity
      this.detail.success.pay_amount.amount = this.successPayAmount
      this.detail.success.processing_fee.amount = this.successProcessingFeeAmount

      this.detail.chargeback.quantity = this.chargebackQuantity
      this.detail.chargeback.pay_amount.amount = this.chargebackPayAmount
      this.detail.chargeback.processing_fee.amount = this.chargebackProcessingFeeAmount

      this.detail.refund.quantity = this.refundQuantity
      this.detail.refund.pay_amount.amount = this.refundPayAmount
      this.detail.refund.processing_fee.amount = this.refundProcessingFeeAmount

      this.detail.tax.source_amount.amount = this.taxAmount

      this.detail.total.quantity = this.totalQuantity
      this.detail.total.pay_amount.amount = this.totalPayAmount
      this.detail.total.processing_fee.amount = this.totalProcessingFeeAmount

      this.detail.settleable.source_amount.amount = this.settleableAmount

      const params = {
        settlement_id: this.settlementId,
        data: this.detail,
      }
      updateSalesStatementDetail(
        params,
        () => {
          showToast(this, 'Success', 'Update SalesStatement Detail Success.')
        },
        fail => {
          showToast(this, 'Warning', `Update SalesStatement Detail Failed with ${fail}`)
        },
      )
    },
  },
}
</script>
